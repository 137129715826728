// import bootstrap customized variables
@import "./shared/variables.module";

// import bootstrap lib
@import "~bootstrap/scss/bootstrap";

// import Montserrat and Poppins fonts
@font-face {
  font-family: Montserrat;
  font-weight: 400;
  src: url("fonts/montserrat-regular-webfont.woff2") format(woff2),
    url("fonts/montserrat-regular-webfont.woff") format(woff);
}
@font-face {
  font-family: Montserrat;
  font-weight: 400;
  font-style: italic;
  src: url("fonts/montserrat-italic-webfont.woff2") format(woff2),
    url("fonts/montserrat-italic-webfont.woff") format(woff);
}
@font-face {
  font-family: Montserrat;
  font-weight: 500;
  src: url("fonts/montserrat-medium-webfont.woff2") format(woff2),
    url("fonts/montserrat-medium-webfont.woff") format(woff);
}
@font-face {
  font-family: Montserrat;
  font-weight: 500;
  font-style: italic;
  src: url("fonts/montserrat-mediumitalic-webfont.woff2") format(woff2),
    url("fonts/montserrat-mediumitalic-webfont.woff") format(woff);
}
@font-face {
  font-family: Montserrat;
  font-weight: 600;
  src: url("fonts/montserrat-semibold-webfont.woff2") format(woff2),
    url("fonts/montserrat-semibold-webfont.woff") format(woff);
}
@font-face {
  font-family: Montserrat;
  font-weight: 600;
  font-style: italic;
  src: url("fonts/montserrat-semibolditalic-webfont.woff2") format(woff2),
    url("fonts/montserrat-semibolditalic-webfont.woff") format(woff);
}
@font-face {
  font-family: Montserrat;
  font-weight: 700;
  src: url("fonts/montserrat-bold-webfont.woff2") format(woff2),
    url("fonts/montserrat-bold-webfont.woff") format(woff);
}
@font-face {
  font-family: Montserrat;
  font-weight: 700;
  font-style: italic;
  src: url("fonts/montserrat-bolditalic-webfont.woff2") format(woff2),
    url("fonts/montserrat-bolditalic-webfont.woff") format(woff);
}

@font-face {
  font-family: Poppins;
  font-weight: 500;
  src: url("fonts/montserrat-medium-webfont.woff2") format(woff2),
    url("fonts/montserrat-medium-webfont.woff") format(woff);
}
@font-face {
  font-family: Poppins;
  font-weight: 500;
  font-style: italic;
  src: url("fonts/montserrat-mediumitalic-webfont.woff2") format(woff2),
    url("fonts/montserrat-mediumitalic-webfont.woff") format(woff);
}
@font-face {
  font-family: Poppins;
  font-weight: 600;
  src: url("fonts/montserrat-semibold-webfont.woff2") format(woff2),
    url("fonts/montserrat-semibold-webfont.woff") format(woff);
}
@font-face {
  font-family: Poppins;
  font-weight: 600;
  font-style: italic;
  src: url("fonts/montserrat-semibolditalic-webfont.woff2") format(woff2),
    url("fonts/montserrat-semibolditalic-webfont.woff") format(woff);
}

// base extra styling
:root,
body,
#root {
  width: 100%;
  height: 100%;
}
.min-h-100 {
  min-height: 100%;
}

// Add BootStrap overrides and missing classes
.fs-7 {
  font-size: $font-size-base;
}

.btn {
  letter-spacing: 1.25px;
  &.btn-primary {
    background-color: $blue-300;
    border: 1px solid transparent;
    &:active {
      background-color: $blue-500;
    }
    &:focus {
      border-color: $blue-500;
    }
    &:hover {
      background-color: $blue-400;
      border-color: $blue-400;
    }
    &:disabled {
      background-color: $blue-300;
      opacity: 0.5;
    }
  }
  &.btn-dark {
    background-color: $black;
    &:hover,
    &:focus {
      background-color: $blue-800;
    }
    &:active {
      background-color: $blue-700;
    }
    &:disabled {
      background-color: $gray-900;
    }
  }

  &.btn-outline-primary {
    color: $primary;
    background: transparent;
    border: 2px solid $primary;
    &:focus {
      border-color: $blue-400;
      color: $blue-400;
      outline: $blue-400 solid 1px;
    }
    &:hover {
      color: $blue-300;
    }
    &:active {
      color: $blue-300;
      border-color: $blue-300;
      &:focus {
        outline-color: $blue-300;
      }
    }
    &:disabled {
      color: $gray;
      border-color: $gray;
    }
  }
  &.btn-outline-secondary {
    color: $black;
    background: $white;
    border: 1px solid $black;
    &:focus {
      outline: $black solid 1px;
    }
    &:hover {
      color: $gray-900;
      border-color: $gray-900;
      &:focus {
        outline-color: $gray-900;
      }
    }
    &:active {
      color: $gray-800;
      border-color: $gray-800;
      background: $gray-100;
      &:focus {
        outline-color: $gray-800;
      }
    }
    &:disabled {
      color: $gray;
      border-color: $gray;
    }
  }
  &.btn-outline-input {
    color: $black;
    background: $input-group-addon-bg;
    border: $input-border-width solid $input-border-color;
    &:focus {
      outline: $input-border-color solid 1px;
    }
    &:hover {
      color: $gray-900;
    }
    &:active {
      color: $teal-800;
      border-color: $teal-800;
      background: $teal-100;
      &:focus {
        outline-color: $gray-800;
      }
    }
    &:disabled {
      color: $gray;
      border-color: $gray;
    }
  }
  &.btn-outline-blue {
    color: $blue-400;
    background: white;
    border: 2px solid $blue-400;
    &:focus {
      outline: $blue-400 solid 1px;
    }
    &:hover {
      color: $blue-500;
      border-color: $blue-500;
      &:focus {
        outline-color: $blue-500;
      }
    }
    &:active {
      color: $blue-600;
      border-color: $blue-600;
      background: $blue-100;
      &:focus {
        outline-color: $blue-600;
      }
    }
    &:disabled {
      color: $gray;
      border-color: $gray;
    }
  }
  &.btn-outline-info {
    color: $info;
    background: white;
    border: 1px solid $info;
    &:focus {
      outline: $info solid 1px;
    }
    &:hover {
      color: $white;
      background-color: $info;
      border-color: $info;
      &:focus {
        outline-color: $info;
      }
    }
    &:active {
      color: $white;
      border-color: shade-color($info, 20%);
      background: $info;
      &:focus {
        outline-color: shade-color($info, 20%);
      }
    }
    &:disabled {
      color: $gray;
      border-color: $gray;
    }
  }
  &.btn-outline-dark {
    border-style: solid;
    border-width: 1px;
    &:hover {
      background-color: $gray-100;
      color: black;
    }
  }
  &.btn-outline-light {
    &:hover {
      background-color: $gray-400;
    }
  }
  &.btn-excel {
    background-color: $green-excel;
    color: $white;
    &:focus {
      outline: shade-color($green-excel, 20%) solid 1px;
    }
    &:active {
      background-color: shade-color($green-excel, 20%);
      &:focus {
        outline-color: shade-color($green-excel, 30%);
      }
    }
  }

  &.rectangular {
    border-radius: $btn-border-radius;
  }
  &.rounded {
    border-radius: $btn-border-radius-lg !important;
  }
  &.transparent {
    background: transparent;
  }

  &.btn-text {
    font-family: inherit;
    font-weight: inherit;
    padding: unset;
    letter-spacing: inherit;
    font-size: inherit;
    line-height: inherit;
    outline: none;
    border-color: transparent;
    &:hover,
    &:active,
    &:focus {
      background-color: inherit;
      outline: none;
      border-color: transparent;
    }
  }
}
// use thinner middle border in button groups
.btn-group > .btn:nth-child(n + 3):not(.dropdown-toggle) {
  &.btn-outline-primary {
    border-left-width: 1px;
    border-left-color: $white;
  }
}

.card {
  --card-width: unset;
  &.card-sm {
    --card-width: #{$modal-sm};
  }
  &.card-md {
    --card-width: #{$modal-md};
  }
  &.card-lg {
    --card-width: #{$modal-lg};
  }
  &.card-xl {
    --card-width: #{$modal-xl};
  }
  max-width: var(--card-width);
}

.form-control:focus,
.input-group:focus-within .input-group-text,
.input-group:focus-within .form-control,
.input-group:focus-within .btn {
  border-color: $teal-600;
}
.input-group:not(.bordered) {
  .form-control:not(:last-child) {
    border-right: none;
  }
  .input-group-text + input.form-control {
    border-left: none;
  }
}

.input-group.is-invalid .input-group-text,
.input-group.is-invalid .form-control {
  border-color: $danger;
}

.form-switch.switch-lg .form-check-input {
  width: 2.5em;
  height: 1.5em;
}

.alert-info {
  --bs-alert-bg: #{$blue-100};
}

.bg-yellow {
  background-color: $yellow;
}

dl {
  dt {
    line-height: 1;
  }
  dd {
    font-weight: 300;
  }
}

.z-index-dropdown {
  z-index: $zindex-dropdown;
}
.z-index-sticky {
  z-index: $zindex-sticky;
}
.z-index-fixed {
  z-index: $zindex-fixed;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $gray-100;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $scrollbar-thumb;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $scrollbar-thumb;
}

/* CSS Scrollbar properties, for browsers not supporting ::-webkit-scrollbar */
.scroller {
  scrollbar-color: $scrollbar-thumb $gray-100;
}
.scroller:hover {
  scrollbar-color: $scrollbar-thumb $gray-100;
}

/*  display a number input field as a textfield, i.e. hides the two arrows at the end of the field*/
.number-input-no-arrows {
  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input,
  & {
    appearance: textfield;
    -moz-appearance: textfield;
  }
}

.max-vh-100 {
  max-height: 100vh;
}

.max-vh-50 {
  max-height: 50vh;
}

// prevent tooltips from flickering scrollbar
body > div[role="tooltip"] {
  position: fixed;
}

.toast {
  position: fixed;
  bottom: 5px;
  right: 0;
  width: unset;
  z-index: $zindex-dropdown !important;
}
