.up {
  transform: rotate(-180deg);
}
.right {
  transform: rotate(-90deg);
}
.left {
  transform: rotate(90deg);
}

.triangle {
  transition: transform 0.25s ease;
}
