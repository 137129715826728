// this files overload bootstrap default sass variables and can be loaded in any sass file
@import "~bootstrap/scss/_functions";

$gray: #9e9e9e;
$gray-100: #f5f5f5;
$gray-200: #eeeeee;
$gray-300: #e0e0e0;
$gray-400: #bdbdbd;
$gray-500: $gray;
$gray-600: #757575;
$gray-700: #616161;
$gray-800: #424242;
$gray-900: #212121;

$blue: #00617e;
$blue-100: #f1f9fa;
$blue-200: #c7f4f5;
$blue-300: #56cdd8;
$blue-400: #2c9cb1;
$blue-500: $blue;
$blue-600: #004b6c;
$blue-700: #00385a;
$blue-800: #002849;
$blue-900: #000b17;

$blue-word: #4082db;

$green: #afc03c;
$green-100: #eef3be;
$green-200: #e4ec6f;
$green-300: #dbf251;
$green-500: $green;
$green-600: #a2bf8d;
$green-700: #669085;
$green-800: #40cd89;
$green-900: #235148;
$green-1000: #10d03a;

$green-excel: #3c8a5e;

$red: #ff3e30;
$red-100: #ffe6d5;
$red-200: #ffc6ac;
$red-300: #ff9f82;
$red-400: #ff7b63;
$red-500: $red;
$red-600: #f27166;

$orange: #ffa263;
$orange-100: #fff3df;
$orange-200: #ffe4c0;
$orange-300: #ffd2a1;
$orange-400: #ffc08a;
$orange-500: $orange;
$orange-600: #db7b48;
$orange-700: #b75831;
$orange-800: #933a1f;
$orange-900: #7a2413;

$teal: #a7ced3;
$teal-100: #f2faf8;
$teal-200: #e5f8f5;
$teal-300: #cfeae8;
$teal-400: #b7d6d6;
$teal-500: $teal;
$teal-600: #6e98a0;
$teal-700: #4c7886;
$teal-800: #30586c;
$teal-900: #1c4059;

$cyan: #1da5f9;
$cyan-100: #d1f8fe;
$cyan-200: #a4ecfe;
$cyan-300: #76dafd;
$cyan-400: #54c5fb;
$cyan-500: $cyan;
$cyan-600: #006e98;
$cyan-700: #00385a;
$cyan-800: #002849;
$cyan-900: #00142d;

$yellow: #ffc83c;
$yellow-100: #fff3bf;
$yellow-200: #ffec99;
$yellow-300: #ffe066;
$yellow-400: #ffd43b;
$yellow-500: $yellow;
$yellow-600: #fab005;
$yellow-700: #f59f00;
$yellow-800: #f08c00;
$yellow-900: #e67700;

$primary: $blue;
$secondary: $teal;
$success: $green-800;
$warning: $orange;
$light: $blue-100;
$dark: $teal-900;
$info: $blue-300;
$black: $blue-900;
$white: #fff;
$uncertainty: $red;

$body-color: $blue-900;

$link-color: $blue-300;
$link-hover-color: $blue-500;

$spacer: 0.75rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4,
  7: $spacer * 5,
  8: $spacer * 6,
);

$font-family-sans-serif: "Montserrat", sans-serif;
$font-size-base: 0.8rem; // 1rem = 16px on most browsers without HDPI or zoom
$font-weight-base: 500;

$h1-font-size: $font-size-base * 6;
$h2-font-size: $font-size-base * 3.75;
$h3-font-size: $font-size-base * 3;
$h4-font-size: $font-size-base * 2.125;
$h5-font-size: $font-size-base * 1.5;
$h6-font-size: $font-size-base * 1.25;
$headings-font-weight: 700;
$headings-line-height: 1.55;

$link-color: $blue-700;
$link-decoration: none;
$link-hover-color: $blue-500;

$border-radius: 0.375rem;
$border-radius-lg: 0.75rem;
$border-radius-xl: 1.5rem;

$input-focus-width: 0;
$input-focus-box-shadow: none;

$btn-font-family: "Poppins", sans-serif;
$btn-font-size: $font-size-base * 0.875;
$btn-font-size-sm: $font-size-base;
$btn-font-size-lg: $font-size-base * 1.125;
$btn-border-width: 1px;
$btn-padding-y-sm: 0.5rem;
$btn-padding-x-sm: 0.5rem;
$btn-padding-y: 0.75rem;
$btn-padding-x: 1.5rem;
$btn-padding-y-lg: $btn-padding-y;
$btn-padding-x-lg: $btn-padding-x;
$btn-border-radius-sm: 6px;
$btn-border-radius: 12px;
$btn-border-radius-lg: 25rem;

$input-padding-y: 1rem;
$input-padding-x: 1rem;

$input-border-color: $teal;
$input-border-width: 2px;

$input-group-addon-bg: transparent;

$form-label-font-weight: 700;

$form-check-transition: background-color 0.3s;
$form-check-input-width: 1.15em;
$form-check-input-bg: transparent;
$form-check-input-border: 2px solid $gray;
$form-check-input-border-radius: 2px;
$form-check-input-focus-box-shadow: 0 0 1px 2px $teal;

$form-check-input-checked-color: $white;
$form-check-input-checked-bg-color: $blue-300;
$form-check-input-checked-border-color: transparent;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='3 3 15 15'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>");

$modal-dialog-margin: 0.5rem;
$modal-dialog-margin-y-sm-up: 0.75rem;
$modal-inner-padding: 2.625rem;
$modal-header-padding-y: 5rem;
$modal-footer-border-width: none;
$modal-header-border-width: none;
$modal-sm: 400px;
$modal-md: 650px;
$modal-lg: 916px;
$modal-xl: calc(100% - #{$modal-dialog-margin} * 4);

$dropdown-min-width: 14rem;

$scrollbar-thumb: rgba(18, 43, 56, 0.25);
$scrollbar-thumb-hover: rgba(18, 43, 56, 0.45);

$box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.05);
$drop-shadow: drop-shadow(3px 5px 2px rgba(0, 0, 0, 0.4));

$dt-font-weight: 500;

$enable-negative-margins: true;

$level-1: $teal-100;
$level-2: $teal-200;
$level-3: tint-color($teal-300, 15%);
$level-4: $teal-300;
$level-5: tint-color($teal-400, 15%);
$level-6: $teal-400;
$level-7: tint-color($teal-500, 15%);
$level-8: $teal-500;
$level-9: tint-color($teal-600, 15%);
$level-10: $teal-600;
$level-11: tint-color($teal-700, 15%);
$level-12: $teal-700;
$level-13: tint-color($teal-800, 15%);
$level-14: $teal-800;
$level-15: tint-color($teal-900, 25%);
$level-16: tint-color($teal-900, 20%);
$level-17: tint-color($teal-900, 15%);
$level-18: tint-color($teal-900, 10%);
$level-19: tint-color($teal-900, 5%);
$level-20: $teal-900;

$level-1-light: $cyan-100;
$level-2-light: $cyan-200;
$level-3-light: tint-color($cyan-300, 15%);
$level-4-light: $cyan-300;
$level-5-light: tint-color($cyan-400, 15%);
$level-6-light: $cyan-400;
$level-7-light: tint-color($cyan-500, 15%);
$level-8-light: $cyan-500;
$level-9-light: tint-color($cyan-600, 15%);
$level-10-light: $cyan-600;
$level-11-light: tint-color($cyan-700, 15%);
$level-12-light: $cyan-700;
$level-13-light: tint-color($cyan-800, 15%);
$level-14-light: $cyan-800;
$level-15-light: tint-color($cyan-900, 25%);
$level-16-light: tint-color($cyan-900, 20%);
$level-17-light: tint-color($cyan-900, 15%);
$level-18-light: tint-color($cyan-900, 10%);
$level-19-light: tint-color($cyan-900, 5%);
$level-20-light: $cyan-900;

$line-difference: $orange-300;

@import "~bootstrap/scss/_variables";

/** set backdrop z-index equal to modal to allox for multiple modals at once
  this way the newer modal backdrop hides older modals */
$zindex-modal-backdrop: $zindex-modal;

$zindex-sticky-table-header: 900;

@import "~bootstrap/scss/_maps";
@import "~bootstrap/scss/_mixins";
